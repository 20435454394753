import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { Button, Card, CardHeader, Container, Row } from "reactstrap";
import { uri } from "../../../constants/API_URLS";
import { getImportedIdeas } from "../../../store/slices/idea/ideaActions";
import keys20 from "./pics/20keys.png";
import arrowRight from "./pics/arrow-right.svg";
import Kaizen from "./pics/japanesse.png";
const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

const ImportedKaizenTemplate = () => {
  let ref = useRef({});
  const ideas = useSelector((state) => state.idea.importedIdeas);
  const [filteredIdeas, setFilteredIdeas] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!ideas?.length) dispatch(getImportedIdeas());
  }, [dispatch, ideas?.length]);
  useEffect(() => {
    if (ideas?.length)
      setFilteredIdeas(
        ideas?.filter((idea) => idea.status.implemented.value === true)
      );
  }, [ideas]);
  return (
    <>
      <SimpleHeader name="Idea Bank" parentName="Kaizen Template" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Kaizen Templates</h3>
              </CardHeader>
              <ToolkitProvider
                data={filteredIdeas}
                keyField="_id"
                columns={[
                  {
                    dataField: "_id",
                    text: "ID",
                    // width: 1,
                    align: "left",
                  },
                  {
                    dataField: "company",
                    text: "Company",
                    sort: true,
                    // width: 1,
                    align: "left",
                  },
                  {
                    dataField: "factory",
                    text: "Factory",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "miniBusiness",
                    text: "Specific location",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "department",
                    text: "Department",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "stage",
                    text: "Stage",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "firstCut",
                    text: "First Cut",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "secondCut",
                    text: "Second Cut",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "ideaText",
                    text: "Idea",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "ideaCode",
                    text: "Idea Code",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "status.implemented.savings",
                    text: "Savings",
                    sort: true,
                    align: "left",
                  },
                  {
                    dataField: "status.implemented.before",
                    text: "Before",
                    formatter: (cell) => (
                      <>
                        {cell ? (
                          <img
                            style={{ paddingRight: "5px" }}
                            src={uri + "/file/" + cell._id}
                            width="250px"
                            alt="Before"
                          />
                        ) : (
                          <small>No provided image</small>
                        )}
                      </>
                    ),
                  },
                  {
                    dataField: "status.implemented.after",
                    text: "After",
                    formatter: (cell) => (
                      <>
                        {cell ? (
                          <img
                            style={{ paddingRight: "5px" }}
                            src={uri + "/file/" + cell._id}
                            width="250px"
                            alt="After"
                          />
                        ) : (
                          <small>No provided image</small>
                        )}
                      </>
                    ),
                  },
                  {
                    dataField: "_id",
                    text: "Generate kaizen journal",
                    formatter: (cell, row) => (
                      <>
                        <div>
                          <ReactToPrint content={() => ref.current}>
                            <PrintContextConsumer>
                              {({ handlePrint }) => {
                                const hanldeClick = () => {
                                  const currentDiv =
                                    document.getElementById(cell);
                                  ref.current = currentDiv;
                                  handlePrint();
                                };
                                return (
                                  <>
                                    <Button
                                      size="sm"
                                      type="button"
                                      color="info"
                                      onClick={hanldeClick}
                                    >
                                      <span className="text-capitalize">
                                        Print
                                      </span>
                                    </Button>
                                  </>
                                );
                              }}
                            </PrintContextConsumer>
                          </ReactToPrint>
                          <div style={{ display: "none" }}>
                            <div
                              style={{ margin: "100px 40px" }}
                              id={cell}
                              ref={ref}
                            >
                              <header>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <img src={Kaizen} width={100} />
                                  <img src={keys20} width={100} />
                                </div>
                                <h1
                                  style={{
                                    textAlign: "center",
                                    fontSize: "40px",
                                  }}
                                >
                                  Key 3 suggestion Implementation Report
                                </h1>
                              </header>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "30px",
                                  height: "500px",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 5,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Current
                                  </p>
                                  <div
                                    style={{
                                      border: "2px solid black",
                                      height: "100%",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src={
                                        uri +
                                        "/file/" +
                                        row.status.implemented.before._id
                                      }
                                      width="100%"
                                      height="100%"
                                      alt="Before"
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "50px",
                                    margin: "auto 0px",
                                  }}
                                >
                                  <img src={arrowRight} />
                                  <img src={arrowRight} />
                                  <img src={arrowRight} />
                                </div>
                                <div
                                  style={{
                                    flex: 5,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                      textAlign: "center",
                                    }}
                                  >
                                    Proposed
                                  </p>
                                  <div
                                    style={{
                                      border: "2px solid black",
                                      height: "100%",
                                      width: "100%",
                                    }}
                                  >
                                    <img
                                      src={
                                        uri +
                                        "/file/" +
                                        row.status.implemented.after._id
                                      }
                                      width="100%"
                                      height="100%"
                                      alt="After"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "100px",
                                  height: "200px",
                                  textAlign: "center",
                                }}
                              >
                                <div
                                  style={{
                                    flex: 1,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Idea Text
                                  </p>
                                  <div
                                    style={{
                                      border: "2px solid black",
                                      height: "100%",
                                      width: "100%",
                                      padding: "15px",
                                    }}
                                  >
                                    <h2>{row.ideaText}</h2>
                                  </div>
                                </div>
                                {/* <div
                                  style={{
                                    flex: 1,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Implemented team
                                  </p>
                                  <div
                                    style={{
                                      border: "2px solid black",
                                      height: "100%",
                                      width: "100%",
                                      padding: "15px",
                                    }}
                                  >
                                    <h2>{row.keyOwner}</h2>
                                  </div>
                                </div> */}
                                <div
                                  style={{
                                    flex: 1,
                                  }}
                                >
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    Effect/Savings
                                  </p>
                                  <div
                                    style={{
                                      border: "2px solid black",
                                      height: "100%",
                                      width: "100%",
                                      padding: "15px",
                                    }}
                                  >
                                    {ideas?.find(e => e._id === row._id).effectOnPQCDSM?.map((el) => (
                                      <h2 key={el.key}>{`${el.key} : ${el.value}%`}</h2>
                                    ))}
                                    <h2>
                                      Savings: {row.status.implemented.savings}
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div style={{ margin: "30px 40px" }} ref={ref}>
                              <header>
                                <h1
                                  className="text-center"
                                  style={{
                                    fontSize: "50px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Kaizen Template
                                </h1>
                              </header>
                              <div
                                style={{
                                  marginTop: "70px",
                                  display: "flex",
                                  gap: "80px",
                                }}
                              >
                                <section>
                                  <h2>Problem: </h2>
                                  <h2>Implemented team: </h2>
                                  <h2>Savings: </h2>
                                </section>
                                <section>
                                  <h2>{row.defineProblem}</h2>
                                  <h2>{row.keyOwner}</h2>
                                  <h2>{row.status.implemented.savings}</h2>
                                </section>
                              </div>
                              <div
                                style={{
                                  marginTop: "50px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <h2 className="text-center">Before</h2>
                                <img
                                  style={{ margin: "30px" }}
                                  src={
                                    uri +
                                    "/file/" +
                                    row.status.implemented.before._id
                                  }
                                  width="80%"
                                  height="400px"
                                  alt="Before"
                                />
                                <h2 className="text-center">After</h2>
                                <img
                                  style={{ margin: "30px" }}
                                  src={
                                    uri +
                                    "/file/" +
                                    row.status.implemented.after._id
                                  }
                                  width="80%"
                                  height="400px"
                                  alt="After"
                                />
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </>
                    ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <SearchBar
                        className="form-control-sm"
                        placeholder=""
                        {...props.searchProps}
                      />
                    </div>
                    <BootstrapTable
                      {...props.baseProps}
                      hover={true}
                      tabIndexCell={true}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={false}
                      keyField="index"
                      data={filteredIdeas?.map((row, index) => ({
                        ...row,
                        index,
                      }))}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ImportedKaizenTemplate;
