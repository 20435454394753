import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import {
  Col,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  Spinner,
  UncontrolledCollapse,
} from "reactstrap";
import SymbiosLogo from "../../assets/img/brand/Symbios-Logo-final-file/PNG/Symbios-English-Logo-4.png";
import Modal from "../../views/pages/components/Modal/Modal";

function AdminNavbar() {
  const [isVisible, setIsVisible] = useState(false);
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const onChangeLanguageButton = () => {
    setIsVisible(true);
    setTimeout(() => {
      history.push("/");
      history.go(0);
      if (i18n.language === "en") {
        i18n.changeLanguage("ar");
      } else if (i18n.language === "ar") {
        i18n.changeLanguage("en");
      }
      setIsVisible(false);
    }, 1500);
  };
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand to="/" tag={Link}>
            <img
              alt="..."
              src={
                require("assets/img/brand/Symbios-Logo-final-file/PNG/Symbios-English-Logo-2.png")
                  .default
              }
            />
          </NavbarBrand>
          <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/admin/dashboard">
                    <img alt="..." src={SymbiosLogo} />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink to="/auth/login" tag={Link}>
                  <span className="nav-link-inner--text">{t("login")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/register" tag={Link}>
                  <span className="nav-link-inner--text">{t("register")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/form" tag={Link}>
                  <span className="nav-link-inner--text">
                    {t("presentYourIdea")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="#" tag={Link}>
                  <span
                    className="nav-link-inner--text"
                    onClick={onChangeLanguageButton}
                  >
                    {t("changeLanguage")}
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/r&r" tag={Link}>
                  <span className="nav-link-inner--text">R&R</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/sap-codes" tag={Link}>
                  <span className="nav-link-inner--text">{t("SAP codes")}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/auth/videos" tag={Link}>
                  <span className="nav-link-inner--text">
                    {t("How to use the system ? (Videos)")}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <hr className="d-lg-none" />
          </UncontrolledCollapse>
        </Container>
      </Navbar>
      <Modal visible={isVisible}>
        <Spinner
          color="light"
          style={{
            height: "5rem",
            width: "5rem",
          }}
        >
          Loading...
        </Spinner>
      </Modal>
    </>
  );
}

export default AdminNavbar;
