import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Row
} from "reactstrap";
import confirm from "reactstrap-confirm";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { getCompanies } from "../../../store/slices/company/companyActions";
import { addFactory } from "../../../store/slices/factory/factoryActions";
import { animatedComponents } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const AddFactory = () => {
    const { t } = useTranslation();
    const companies = useSelector((state) => state?.company?.companies) || [];
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const history = useHistory();
    const dispatch = useDispatch();
    const englishName = register("englishName", {
        required: "Please enter factory name in English",
        minLength: {
            value: 4,
            message: "Factory name must be at least 4 characters",
        },
    });
    const arabicName = register("arabicName", {
        required: "Please enter factory name in Arabic",
        minLength: {
            value: 4,
            message: "Factory name must be at least 4 characters",
        },
    });
    const company = register("company", {
        required: "Please select at least one",
    });
    const handleFormSubmit = async ({ englishName, arabicName, company }) => {
        const factory = {
            name: {
                en: englishName,
                ar: arabicName
            },
            company: company.map(el => el.value)
        }
        let result = await confirm({
            title: <>Warning</>,
            message: t("Are you sure you want to submit this?"),
            confirmText: "Confirm",
            confirmColor: "primary",
            cancelColor: "link text-danger",
        });
        if (result) {
            dispatch(addFactory(factory));
            history.push("/admin/factory");
            history.go(0);
        };
    };
    useEffect(() => {
        if (!companies.legth)
            dispatch(getCompanies());
    }, []);
    return (
        <>
            <SimpleHeader name="Add Factory" parentName="Management" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Add</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form" onSubmit={handleSubmit(handleFormSubmit)}>
                                        <div className="form-row">
                                            <Col className="mb-3" md="4">
                                                <Controller
                                                    rules={{ required: "This is required" }}
                                                    name="company"
                                                    control={control}
                                                    onChange={company.onChange}
                                                    onBlur={company.onBlur}
                                                    innerRef={company.ref}
                                                    render={({ field }) => (
                                                        <>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="validationCustom03"
                                                            >
                                                                Company
                                                            </label>
                                                            <Select
                                                                {...field}
                                                                closeMenuOnSelect={false}
                                                                components={animatedComponents}
                                                                isMulti
                                                                options={companies?.map((el) => {
                                                                    return {
                                                                        value: el._id,
                                                                        label: el.name.en,
                                                                    };
                                                                })}
                                                            />
                                                        </>
                                                    )}
                                                />
                                                <small className="text-danger">
                                                    {errors.company && errors.company.message}
                                                </small>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    English Name
                                                </label>
                                                <Input
                                                    id="validationCustom01"
                                                    placeholder="English Name..."
                                                    type="text"
                                                    name="englishName"
                                                    onChange={englishName.onChange}
                                                    onBlur={englishName.onBlur}
                                                    innerRef={englishName.ref}
                                                />
                                                <small className="text-danger">
                                                    {errors.englishName && errors.englishName.message}
                                                </small>
                                            </Col>
                                            <Col className="mb-3" md="4">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="validationCustom01"
                                                >
                                                    Arabic Name
                                                </label>
                                                <Input
                                                    id="validationCustom01"
                                                    placeholder="Arabic Name..."
                                                    type="text"
                                                    name="arabicName"
                                                    onChange={arabicName.onChange}
                                                    onBlur={arabicName.onBlur}
                                                    innerRef={arabicName.ref}
                                                />
                                                <small className="text-danger">
                                                    {errors.arabicName && errors.arabicName.message}
                                                </small>
                                            </Col>
                                        </div>
                                        <Button color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default AddFactory;
