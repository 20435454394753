import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Select2 from "react-select2-wrapper";
import {
    Button,
    Card,
    CardBody,
    CardHeader, Container,
    Form, Input, Row, Spinner
} from "reactstrap";
import confirm from "reactstrap-confirm";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { updateIdea } from "../../../store/slices/idea/ideaActions";
import { effectOnPQCDSMArray, kaizenTypes } from "../../../utils/Data";
import { filterValuesInIdeasFilter } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";
import { getBusinessUnits } from "../../../store/slices/factoryMinibusinessUnit/factoryMinibusinessUnitActions";
import { getareaSections } from "../../../store/slices/areaSection/areaSectionActions";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import FilesImage from "../../../components/FilesImage/FilesImage";
import { useAppDispatch } from "../../../store/store";
import { setIsLoading } from "../../../store/slices/shared/sharedSlice";
import Modal from "../components/Modal/Modal";

// import { getAICMinibusinesses } from "../../../store/slices/AICMinibusiness/AICMinibusinessActions";



function EditIdeaFields() {
    const { t, i18n } = useTranslation();
    const factoryBusinessUnits = useSelector((state) => state?.factoryBusinessUnit?.businessUnits);
    const areaSections = useSelector((state) => state?.areaSection?.areaSections);
    // const aicMinibusinesses = useSelector((state) => state?.AICMinibusiness?.AICMinibusinesses);

    const dispatch = useAppDispatch();
    const history = useHistory();
    const id = useParams().id;
    const isLoading = useSelector((state) => state.shared.isLoading);

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();
    let watchedData = watch();
    const { data } = useLocation().state;
    let filteredAreaSection = (filterValuesInIdeasFilter(
        areaSections,
        "factoryBusinessUnit",
        watchedData.factoryBusinessUnit,
        true
    )?.map((el) => {
        return {
            id: el._id,
            value: el._id,
            text: el.name.en,
        };
    }))
    const ideaText = register("ideaText", {
        required: "This is required",
        minLength: {
            value: 20,
            message: "Idea must be at least 20 characters",
        },
    });
    const ideaDesc = register("ideaDesc", {
        required: "This is required",
        minLength: {
            value: 20,
            message: "Idea must be at least 20 characters",
        },
    });
    const employeeName = register("employeeName", {
        required: "This is required",
        minLength: {
            value: 4,
            message: "Idea Code must be at least 4 characters",
        },
    });
    const employeeCode = register("employeeCode", {
        required: "This is required",
        minLength: {
            value: 4,
            message: "Idea Code must be at least 4 characters",
        },
    });
    const estimatedAnualSavings = register("estimatedAnualSavings", {
        valueAsNumber: true,
    });

    const onSubmit = async (submittedData) => {
        try {
            let result = await confirm({
                title: <>Warning</>,
                message: t("Are you sure you want to submit this?"),
                confirmText: "Confirm",
                confirmColor: "primary",
                cancelColor: "link text-danger",
            });
            if (result) {
                dispatch(setIsLoading(true))
                dispatch(updateIdea({
                    idea: {
                        data: submittedData,
                        key: 'none',
                        id: id
                    },
                    file: submittedData.filesRelated ?? '',
                    operatorImg: submittedData.operatorImg ?? "",
                    dispatch, path: '/admin/idea', history, setIsLoading
                }));
            }
        } catch (e) {
            return e;
        }
    }
    useEffect(() => {
        dispatch(getBusinessUnits());
        dispatch(getareaSections());
        // dispatch(getAICMinibusinesses());
    }, [])
    return (
        <>
            <SimpleHeader name="Edit Idea" parentName="Idea management" />
            <Modal visible={isLoading} >
                <Spinner
                    color="light"
                    style={{
                        height: "5rem",
                        width: "5rem",
                    }}
                >
                    Loading...
                </Spinner>
            </Modal>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Edit</h3>
                                </CardHeader>
                                <CardBody>
                                    <Form role="form"
                                        onSubmit={handleSubmit(onSubmit)}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "30px",
                                        }} className="m-5" md='4' >
                                        {/* factoryBusinessUnit */}
                                        <Row xs="11" md="9">
                                            <Controller
                                                rules={{ required: "This is required" }}
                                                defaultValue={data?.factoryBusinessUnit?._id}
                                                name="factoryBusinessUnit"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <>
                                                            <h3 className="form-control-label">Location of waste</h3>
                                                            <Select2
                                                                {...field}
                                                                options={{
                                                                    placeholder: "Choose your Location of waste",
                                                                }}
                                                                data-minimum-results-for-search="Infinity"
                                                                data={factoryBusinessUnits?.map((el) => {
                                                                    return {
                                                                        id: el._id,
                                                                        value: el._id,
                                                                        text: el.name.en,
                                                                    };
                                                                })}
                                                            />
                                                        </>
                                                    );
                                                }}
                                            />
                                            <ErrorMessage errors={errors} property="factoryBusinessUnit" />
                                        </Row>
                                        {/* areaSection */}
                                        <Row xs="11" md="9">
                                            {(data?.areaSection?._id || filteredAreaSection[0]?.id) && <Controller
                                                rules={{ required: "This is required" }}
                                                defaultValue={data?.areaSection?._id ? data?.areaSection?._id : filteredAreaSection[0]?.id}
                                                name="areaSection"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <>
                                                            <h3 className="form-control-label">Specific location</h3>
                                                            <Select2
                                                                {...field}
                                                                options={{
                                                                    placeholder: "Choose your Specific location",
                                                                }}
                                                                data-minimum-results-for-search="Infinity"
                                                                data={filteredAreaSection}
                                                            />
                                                        </>
                                                    );
                                                }}
                                            />}
                                            <ErrorMessage errors={errors} property="areaSection" />
                                        </Row>
                                        {/* AICMinibusiness */}
                                        {/* <Row xs="11" md="9">
                                            <Controller
                                                rules={{ required: "This is required" }}
                                                defaultValue={data?.AICMinibusiness?._id}
                                                name="AICMinibusiness"
                                                control={control}
                                                render={({ field }) => {
                                                    return (
                                                        <>
                                                            <h3 className="form-control-label">
                                                                Minibusiness
                                                            </h3>
                                                            <Select2
                                                                {...field}
                                                                options={{
                                                                    placeholder: "Choose your Minibusiness",
                                                                }}
                                                                data-minimum-results-for-search="Infinity"
                                                                data={aicMinibusinesses?.map((el) => {
                                                                    return {
                                                                        id: el._id,
                                                                        value: el._id,
                                                                        text: el.name.en,
                                                                    };
                                                                })}
                                                            />
                                                        </>
                                                    );
                                                }}
                                            />
                                            <ErrorMessage errors={errors} property="AICMinibusiness" />
                                        </Row> */}
                                        {/* ideaText */}
                                        <Row xs="11" md="9">
                                            <h3 className="form-control-label">Idea</h3>
                                            <Input
                                                defaultValue={data.ideaText}
                                                type="textarea"
                                                name="ideaText"
                                                onChange={ideaText.onChange}
                                                onBlur={ideaText.onBlur}
                                                innerRef={ideaText.ref}
                                                style={{ maxHeight: "300px" }}
                                            />
                                            <ErrorMessage errors={errors} property="ideaText" />
                                        </Row>
                                        {/* ideaDesc */}
                                        <Row xs="11" md="9">
                                            <h3 className="form-control-label">Idea Description</h3>
                                            <Input
                                                defaultValue={data.ideaDesc}
                                                type="textarea"
                                                name="ideaDesc"
                                                onChange={ideaDesc.onChange}
                                                onBlur={ideaDesc.onBlur}
                                                innerRef={ideaDesc.ref}
                                                style={{ maxHeight: "300px" }}
                                            />
                                            <ErrorMessage errors={errors} property="ideaDesc" />
                                        </Row>
                                        {/* employeeName */}
                                        <Row xs="11" md="9">
                                            <h3 className="form-control-label">Empolyee name</h3>
                                            <Input
                                                defaultValue={data.employeeName}
                                                type="text"
                                                name="employeeName"
                                                onChange={employeeName.onChange}
                                                onBlur={employeeName.onBlur}
                                                innerRef={employeeName.ref}
                                            />
                                            <ErrorMessage errors={errors} property="employeeName" />
                                        </Row>
                                        {/* employeeCode */}
                                        <Row xs="11" md="9">
                                            <h3 className="form-control-label">Empolyee code</h3>
                                            <Input
                                                defaultValue={data.employeeCode}
                                                type="text"
                                                name="employeeCode"
                                                onChange={employeeCode.onChange}
                                                onBlur={employeeCode.onBlur}
                                                innerRef={employeeCode.ref}
                                            />
                                            <ErrorMessage errors={errors} property="employeeCode" />
                                        </Row>
                                        {/* effect on pqcdsm */}
                                        <Row xs="11" md="9">
                                            <h3 style={{ width: "100%" }}>
                                                {t("form.effectOnPQCDSM")}
                                            </h3>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "10px",
                                                    width: "100%",
                                                }}
                                            >
                                                {effectOnPQCDSMArray.map((effect) => (
                                                    <CustomCheckbox
                                                        key={effect.id}
                                                        value={effect.text.en}
                                                        checked={data.effectOnPQCDSM?.includes(effect.text.en) || data.effectOnPQCDSM?.includes(effect.text.ar)}
                                                        id={effect.id}
                                                        label={effect.text[i18n.language]}
                                                        register={register("effectOnPQCDSM", {
                                                            required: "This is required.",
                                                        })}
                                                    />
                                                ))}
                                            </div>
                                            <ErrorMessage errors={errors} property="effectOnPQCDSM" />
                                        </Row>
                                        {/* kaizenType */}
                                        <Row xs="11" md="9">
                                            <Controller
                                                defaultValue={data.kaizenType || kaizenTypes[0].text[i18n.language]}
                                                rules={{ required: "This is required" }}
                                                name="kaizenType"
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <h3 className="form-control-label">kaizen Type</h3>
                                                        <Select2
                                                            {...field}
                                                            options={{
                                                                placeholder: "Choose the kaizen type",
                                                            }}
                                                            data-minimum-results-for-search="Infinity"
                                                            data={kaizenTypes?.map((el) => {
                                                                return {
                                                                    id: el.text.en,
                                                                    value: el.text.en,
                                                                    text: el.text[i18n.language],
                                                                };
                                                            })}
                                                        />
                                                    </>
                                                )}
                                            />
                                            <ErrorMessage errors={errors} property="kaizenType" />
                                        </Row>
                                        {/* estimatedAnualSavings */}
                                        <Row xs="11" md="9">
                                            <h3 className="form-control-label">
                                                Estimated Annual Savings
                                            </h3>
                                            <Input
                                                defaultValue={data.estimatedAnualSavings}
                                                type="number"
                                                min={0}
                                                name="estimatedAnualSavings"
                                                onChange={estimatedAnualSavings.onChange}
                                                onBlur={estimatedAnualSavings.onBlur}
                                                innerRef={estimatedAnualSavings.ref}
                                            />
                                            <ErrorMessage errors={errors} property="estimatedAnualSavings" />
                                        </Row>
                                        {/* files related */}
                                        <Row xs="11" md="9">
                                            <h3>{t("form.uploadYourFiles")}</h3>
                                            <div className="custom-file">
                                                <Controller
                                                    control={control}
                                                    name="filesRelated"
                                                    render={({
                                                        field: { value, onChange, ...field },
                                                    }) => {
                                                        return (
                                                            <input
                                                                {...field}
                                                                className="custom-file-input"
                                                                onChange={(event) => {
                                                                    onChange(event.target.files[0]);
                                                                }}
                                                                type="file"
                                                                id="filesRelated"
                                                            />
                                                        );
                                                    }}
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="customFileLang"
                                                >
                                                    {t("form.selectFile")}
                                                </label>
                                            </div>
                                            {data.filesRelated[0]?._id && <FilesImage path={data.filesRelated[0]?._id} alt="Files Related" />}
                                            <ErrorMessage errors={errors} property="filesRelated" />
                                        </Row>
                                        {/* upload your photo */}
                                        <Row xs="11" md="9">
                                            <h3>{t("form.operatorImg")}</h3>
                                            <div className="custom-file">
                                                <Controller
                                                    defaultValue={data.operatorImg}
                                                    control={control}
                                                    name="operatorImg"
                                                    render={({
                                                        field: { value, onChange, ...field },
                                                    }) => {
                                                        return (
                                                            <input
                                                                {...field}
                                                                className="custom-file-input"
                                                                onChange={(event) => {
                                                                    onChange(event.target.files[0]);
                                                                }}
                                                                type="file"
                                                                id="operatorImg"
                                                            />
                                                        );
                                                    }}
                                                />
                                                <label className="custom-file-label">
                                                    {t("form.selectFile")}
                                                </label>
                                            </div>
                                            {data.operatorImg && <FilesImage path={data.operatorImg} alt="Files Related" />}
                                            <ErrorMessage errors={errors} property="operatorImg" />
                                        </Row>
                                        <div className="text-center">
                                            <Button color="primary" className="my-4" >
                                                {t('submit')}
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default EditIdeaFields