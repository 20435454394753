import { createSlice, current } from "@reduxjs/toolkit";
import {
  addSAPcodes,
  deleteSAPcodes,
  editSAPcodes,
  getSAPcodes,
} from "./SAPcodesActions";
const initialState = {
  SAPcodes: [],
};

const SAPcodesSlice = createSlice({
  name: "SAPcodes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSAPcodes.fulfilled, (state, action) => {
      state.SAPcodes = action.payload;
    });
    builder.addCase(addSAPcodes.fulfilled, (state, action) => {
      const newState = current(state).push(action.payload);
      state.SAPcodes = newState;
    });
    builder.addCase(deleteSAPcodes.fulfilled, (state, action) => {
      const newState = current(state).SAPcodes.filter(
        (AICMinibusiness) => AICMinibusiness._id !== action.payload
      );
      state.SAPcodes = newState;
    });
    builder.addCase(editSAPcodes.fulfilled, (state, action) => {
      const newState = current(state).SAPcodes.map((AICMinibusiness) => {
        if (AICMinibusiness._id === action.payload._id) {
          return action.payload;
        } else {
          return AICMinibusiness;
        }
      });
      state.SAPcodes = newState;
    });
  },
});

export default SAPcodesSlice.reducer;
