import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URLS } from "../../../constants/API_URLS";

const getSAPcodes = createAsyncThunk("SAPcodes/all", async () => {
  console.log("in dis");
  try {
    const response = await axios.get(API_URLS.SAPcodes.get);
    return response.data;
  } catch (error) {
    return error;
  }
});

const addSAPcodes = createAsyncThunk(
  "SAPcodes/post",
  async ({ file, alertFun, t }) => {
    try {
      const token = localStorage.getItem("opexDashbaordToken");
      let requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
      let rAndrPDF = new FormData();
      rAndrPDF.append("file", file);
      const rAndrPDFResponse = await axios.post(
        API_URLS.File.upload,
        rAndrPDF,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const rAndrRecord = {
        fileId: rAndrPDFResponse.data[0]?._id,
      };
      const response = await axios.post(
        API_URLS.SAPcodes.post,
        rAndrRecord,
        requestOptions
      );
      alertFun("success", t("Your file has been uploaded"));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      return response.data;
    } catch (error) {
      alertFun("danger", t("An error occured please try again"));
      return error;
    }
  }
);
const editSAPcodes = createAsyncThunk("SAPcodes/edit", async (data) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const url = API_URLS.SAPcodes.put.replace("{Id}", data._id);
    await axios.put(url, data, requestOptions);
    return data;
  } catch (e) {
    return e;
  }
});

const deleteSAPcodes = createAsyncThunk("SAPcodes/delete", async (id) => {
  try {
    const token = localStorage.getItem("opexDashbaordToken");
    const requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const url = API_URLS.SAPcodes.delete.replace("{Id}", id);
    await axios.delete(url, requestOptions);
    return id;
  } catch (e) {
    return e;
  }
});

export { addSAPcodes, deleteSAPcodes, getSAPcodes, editSAPcodes };
