import { combineReducers } from "@reduxjs/toolkit";
import { questionsSlice } from "store/slices/questions/questionsSlice";
import { authSlice } from "store/slices/auth/authSlice";
import { userSlice } from "store/slices/users/userSlice";
import { analyticsSlice } from "store/slices/analytics/analyticsSlice";
import { companySlice } from "./slices/company/companySlice";
import { ideaSlice } from "./slices/idea/ideaSlice";
import factorySlice from "./slices/factory/factorySlice";
import miniBusinessSlice from "./slices/mini-business/miniBusinessSlice";
import departmentSlice from "./slices/department/departmentSlice";
import stageSlice from "./slices/stage/stageSlice";
import firstCutSlice from "./slices/firstCut/firstCutSlice";
import secondCutSlice from "./slices/secondCut/secondCutSlice";
import factoryMinibusinessUnitSlice from "./slices/factoryMinibusinessUnit/factoryMinibusinessUnitSlice";
import areaSectionSlice from "./slices/areaSection/areaSectionSlice";
import AICMinibusinessSlice from "./slices/AICMinibusiness/AICMinibusinessSlice";
import sharedSlice from "./slices/shared/sharedSlice";
import RewardAndRecognitionSlice from "./slices/RewardAndRecognition/RewardAndRecognitionSlice";
import EmployeeCodeSheetSlice from "./slices/EmployeeCodeSheet/EmployeeCodeSheetSlice";
import SAPcodesSlice from "./slices/SAPcodes/SAPcodesSlice";
import videosSlice from "./slices/videos/videosSlice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  user: userSlice.reducer,
  questions: questionsSlice.reducer,
  analytics: analyticsSlice.reducer,
  company: companySlice.reducer,
  idea: ideaSlice.reducer,
  factory: factorySlice,
  miniBusiness: miniBusinessSlice,
  department: departmentSlice,
  stage: stageSlice,
  firstCut: firstCutSlice,
  secondCut: secondCutSlice,
  factoryBusinessUnit: factoryMinibusinessUnitSlice,
  areaSection: areaSectionSlice,
  AICMinibusiness: AICMinibusinessSlice,
  RewardAndRecognition: RewardAndRecognitionSlice,
  shared: sharedSlice,
  employeeCodeSheets: EmployeeCodeSheetSlice,
  videos: videosSlice,
  sapCodes: SAPcodesSlice,
});

export default rootReducer;
