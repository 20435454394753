import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { Button, Card, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import ErrorMessage from "../../../../components/ErrorMessage/ErrorMessage";
import { countTrueProps } from "../../../../shared/utils";
import { rejectedReasons, statusApproval } from "../../../../utils/Data";
import { notify, validateAndAddError } from "../../../../utils/helpers";
import ToggleCheckbox from "../../components/ToggleCheckbox/ToggleCheckbox";

const Evaluated = ({ data, view, setChangedData, id }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const notificationAlertRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [checkboxes, setCheckboxes] = useState({
    isItCheap: false,
    isItEasyToImplement: false,
    canItBeImplementedWithin3Weeks: false,
    isItOneOfOurTop3FavSuggestions: false,
    willItSaveAsignificantAmout: false,
    isItUrgent: false,
    canWeImplementItOurselves: false,
    canBeReplicated: false,
  });
  const [dueDate, setDueDate] = useState(new Date().toISOString().split('T')[0]);
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [approved, setApproved] = useState(data[1]?.accepted || true);
  const [rejectedReason, setRejectedReason] = useState('High cost');
  const [holdReason, setHoldReason] = useState(!view && data[1]?.accepted === 'hold' ? data[1]?.reasonOfHold : '');
  const [other, setOther] = useState('');
  const [responsiblePersons, setResponsiblePersons] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);

  const handleCheckboxChange = (e, key) => {
    !dataChanged && setDataChanged(true);
    setCheckboxes((prevState) => ({
      ...prevState,
      [key]: e.target.checked,
    }));
  };

  const handleAddResponsiblePerson = () => {
    setResponsiblePersons((prevPersons) => [
      ...prevPersons,
      responsiblePerson,
    ]);
    setResponsiblePerson("");
  }

  const saveChangesHandler = () => {
    let errors = {};
    // Check for validation errors
    if (!((/hold/i).test(approved) || (/false/i).test(approved))) {
      validateAndAddError(errors, 'responsiblePerson', responsiblePersons, 'array', i18n.language);

      setValidationErrors(errors);

      if (Object.keys(errors).length > 0) {
        return;
      }
    }

    notify(
      "success",
      'Your changes have been saved.',
      notificationAlertRef,
      'Saved Successfully'
    );
    const evaluatedChanges = {
      questions: {
        ...checkboxes,
        totalTicks: countTrueProps(checkboxes),
        priority: countTrueProps(checkboxes) >= 4 ? "High" : "Low",
        dueDate: dueDate,
        responsiblePerson: responsiblePersons,
      },
      canBeReplicated: checkboxes.canBeReplicated,
      approvedBy: JSON.parse(localStorage.getItem("user")).username,
      accepted: approved,
      reasonOfDenial: rejectedReason === "Other" ? other : approved && rejectedReason,
      value: true,
      date: new Date(),
      reasonOfHold: holdReason
    }
    if ((/hold/i).test(approved)) {
      delete evaluatedChanges.value;
      delete evaluatedChanges.date;
    }
    delete evaluatedChanges.questions.canBeReplicated
    setChangedData({
      data: {
        evaluated:
          evaluatedChanges,
      },
      key: 'evaluated',
      id: id
    });
  }

  return (
    <Card className="w-100 p-3">
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <CardHeader>
        <p className="h3 text-capitalize"> {t('evaluated.evaluated')} </p>
      </CardHeader>

      <Row className="w-100">
        <div className="form-row">
          {/* checkboxes questions */}
          {Object.keys(checkboxes).map(checkbox => <ToggleCheckbox
            key={checkbox}
            checkboxes={checkboxes}
            data={data}
            handleCheckboxChange={handleCheckboxChange}
            view={view}
            name={checkbox}
            text={t(`evaluated.${checkbox}`)}
          />)}

          {/* Due date */}
          <Col className="m-5" md="4">
            <p className="h3">{t('evaluated.Due date')}</p>
            <Input
              disabled={view}
              value={view ? data[1].questions.dueDate : dueDate}
              type="date"
              onChange={(e) => setDueDate(e.target.value)}
            />
          </Col>

          {/* Responsible person */}
          <Col className="m-5" md="4">
            <p className="h3">{t('evaluated.Responsible person')}</p>
            <Input
              placeholder="Responsible person"
              disabled={view}
              value={responsiblePerson}
              onChange={(e) => setResponsiblePerson(e.target.value)}
              type="text"
            />
            <Button
              color="primary"
              type="button"
              disabled={view}
              style={{ width: "150px", margin: "10px 0px" }}
              onClick={handleAddResponsiblePerson}
            >
              {t('submit')}
            </Button>
            <ul style={{ listStyleType: "none" }}>
              {view ? data[1].questions.responsiblePerson?.map((el) => (
                <li key={el}> {el} </li>
              )) : responsiblePersons?.map((el) => (
                <li key={el}> {el} </li>
              ))}
            </ul>
            <ErrorMessage errors={validationErrors} property='responsiblePerson' />
          </Col>

          {/* approved*/}
          <Col className="m-5" md="4">
            <FormGroup>
              <p className="h3">Evaluation decision</p>
              <Input
                type="select"
                disabled={view}
                value={approved}
                onChange={(e) => {
                  setApproved(e.target.value);
                  setDataChanged(true)
                }}
              >
                {statusApproval.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
              </Input>
            </FormGroup>
          </Col>

          {/* hold reason*/}
          {(/hold/i).test(approved) && <Col className="m-5" md="4">
            <p className="h3">{t('evaluated.hold reason')}</p>
            <Input
              placeholder={t('evaluated.hold reason')}
              value={holdReason}
              onChange={(e) => setHoldReason(e.target.value)}
              type="text"
            />
          </Col>}

          {/* rejected reason*/}
          {(/false/i).test(approved) && <Col className="m-5" md="4">
            <FormGroup>
              <p className="h3">{t('evaluated.rejected reason')}</p>
              <Input
                type="select"
                disabled={view}
                value={rejectedReason}
                onChange={(e) => setRejectedReason(e.target.value)}
              >
                {rejectedReasons.map(el => <option key={el.label} value={el.value}>{t(`approved.${el.label}`)}</option>)}
              </Input>
            </FormGroup>
          </Col>}

          {/* other */}
          {((/false/i).test(approved) && rejectedReason === 'Other') && <Col className="m-5" md="4">
            <p className="h3">{t('evaluated.Other')}</p>
            <Input
              placeholder="Other"
              disabled={view}
              value={other}
              onChange={(e) => {
                setOther(e.target.value)
              }}
              type="text"
            />
          </Col>}
        </div>
      </Row>
      <div className="text-center">
        {!view && <Button className="my-4" color="primary" type="button" onClick={saveChangesHandler} disabled={!dataChanged || view} >
          {t('evaluated.saveChanges')}
        </Button>}
      </div>
    </Card>
  );
};
export default Evaluated;
